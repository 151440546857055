import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';
import { AppContext } from '../App';

export default function TablePaginationDemo() {
    const { tablePage, tableDocs, setTablePage, setTableRowPerPage } = React.useContext(AppContext)
    // useEffect(() => {
    //   console.log(totalDocs)
    // }, [totalDocs])
    
    const handleChangePage = (
        event,
        newPage,
    ) => {
        console.log(tablePage, newPage)
        setTablePage(newPage);
    };

    const handleChangeRowsPerPage = (
        event
    ) => {
        setTableRowPerPage(parseInt(event.target.value));
    };

    return (
        <TablePagination
            component="div"
            count={tableDocs}
            page={tablePage}
            onPageChange={handleChangePage}
            rowsPerPage={10}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    );
}