import { Info } from '@mui/icons-material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { AppContext } from '../App'

export default function Saldo() {
    const context = useContext(AppContext)
    const useEffectOnce = effect => {
        const destroyFunc = useRef();
        const calledOnce = useRef(false);
        const renderAfterCalled = useRef(false);

        if (calledOnce.current) {
            renderAfterCalled.current = true;
        }

        useEffect(() => {
            if (calledOnce.current) {
                return;
            }

            calledOnce.current = true;
            destroyFunc.current = effect();

            return () => {
                if (!renderAfterCalled.current) {
                    return;
                }

                if (destroyFunc.current) {
                    destroyFunc.current();
                }
            };
        }, []);
    }

    useEffectOnce(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const nmid = urlParams.get('nmid');
        const clientId = urlParams.get('clientId');
        const alias = urlParams.get('alias');
        context.getBalance(clientId, nmid)
    }, [])
    return (
        <>
            <div style={{ width: "100%" }}>
                <div style={{ padding: "20px 10px", width: "100%", maxWidth: "350px", margin: "20px", border: "1px rgb(204, 204, 204) solid", borderRadius: "10px" }}>
                    {context?.auth?.user?.balanceSettled && (
                        <div style={{ padding: "10px 20px" }}>
                            <a
                                data-tooltip-id="my-tooltip"
                                href='#/'
                                data-tooltip-content="Settled balance adalah nominal yang dapat digunakan melalui BI Fast atau metode transfer lain secara API. Bila terdapat settled balance sampai jam cut off dari PIRO akan di transfer pada rekening default"
                                style={{ textDecoration: "none", color: "black" }}
                            >
                                <h4>Settled <Info fontSize='25px'></Info></h4>
                            </a>

                            <Tooltip className='z-100' id="my-tooltip" content="Settled balance adalah nominal yang dapat digunakan melalui BI Fast atau metode transfer lain secara API. Bila terdapat settled balance sampai jam cut off dari PIRO akan di transfer pada rekening default">
                            </Tooltip>

                            {context.balance?.current_account?.settled?.balance ? (
                                <h5>
                                    {context.balance?.current_account?.settled?.balance?.toLocaleString(
                                        "id-ID",
                                        { style: "currency", currency: "IDR" }
                                    )}
                                </h5>
                            ) :
                                (<h5>0</h5>)
                            }
                        </div>
                    )}

                    {context?.auth?.user?.balanceUnsettled && (
                        <div style={{ padding: "10px 20px" }}>
                            <a
                                data-tooltip-id="my-tooltip"
                                href='#/'
                                data-tooltip-content="Unsettled adalah transaksi yang telah sukses terjadi namun membutuhkan waktu distribusi dari pihak ke 3 diluar Piro. Setelah diterima Piro akan dipindahkan ke Settled setelah dikurangi biaya yang muncul."
                                style={{ textDecoration: "none", color: "black" }}
                            >
                                <h4>Unsettled<Info fontSize='25px'></Info></h4>
                            </a>

                            <Tooltip className='z-100' id="my-tooltip" content="Unsettled adalah transaksi yang telah sukses terjadi namun membutuhkan waktu distribusi dari pihak ke 3 diluar Piro. Setelah diterima Piro akan dipindahkan ke Settled setelah dikurangi biaya yang muncul.">
                            </Tooltip>

                            {context.balance?.current_account?.unsettled?.balance ? (
                                <h5>
                                    {context.balance?.current_account?.unsettled?.balance?.toLocaleString(
                                        "id-ID",
                                        { style: "currency", currency: "IDR" }
                                    )}
                                </h5>
                            ) :
                                (<h5>0</h5>)
                            }
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
