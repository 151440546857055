import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'; // Import for potential navigation
import {
    TextField,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Alert,
    Snackbar,
    AlertTitle,
} from '@mui/material'; // Import MUI components
import axios from 'axios';
import { socket } from '../socket';
import { AppContext } from '../App';
import { url } from '../BaseUrl';

export default function TopUp() {
    const [open, setOpen] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    useEffect(() => {
        function onVaNotify(value) {
            handleModalClose()
            setOpenSnack(true)
            console.log(value)
        }
        socket.connect();
        socket.on('va-notify', onVaNotify);

        return () => {
            socket.off('va-notify', onVaNotify);
            socket.disconnect();
        };
    }, []);


    const handleModalOpen = () => {
        setOpen(true);
    };

    const handleModalClose = () => {
        setOpen(false);
    };
    const context = useContext(AppContext)
    const { nmid } = context
    const [topUpAmount, setTopUpAmount] = useState(''); // State for top-up amount
    const [paymentMethod, setPaymentMethod] = useState(''); // State for payment method
    const [virtualAccount, setVirtualAccount] = useState({}); // State for payment method

    const handleTopUpAmountChange = (event) => {
        setTopUpAmount(event.target.value);
    };

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    const handleTopUpSubmit = async (event) => {
        setVirtualAccount({})
        event.preventDefault();
        try {
            if (!topUpAmount || !paymentMethod) return alert("Harap isi semua field dibawah")
            const [, clientId, alias] = nmid.split(":")
            const uri = "https://us-central1-doc-basic.cloudfunctions.net/dashboard2gen/v1/generate-nobu-bill-va"
            const headers = {
                Authorization: "Basic ZGFzaDokJSREJEFTSA==",
                client_id: clientId,
                admin_id: "Y0dHbYgWSQvH41QO9uwq"
            }
            const body = {
                virtualAccountName: alias,
                orderId: `${url}/callback-va`,
                value: Number(topUpAmount).toFixed(2).toString()
            }
            const responseGenerateVA = await axios.post(uri, body, { headers })
            const { ID, orderId, value } = responseGenerateVA.data
            setVirtualAccount({ number: ID, nominal: Number(value).toLocaleString("ID"), orderId })
        } catch (error) {
            alert(error)
        }


        console.log(`Top-up amount: ${topUpAmount}, Payment method: ${paymentMethod}`);

        // Handle successful top-up and potentially navigate to a confirmation page
        // (consider using conditional rendering or navigation based on the response)
    };

    useEffect(() => {
        const { number, nominal } = virtualAccount
        if (number && nominal) {
            handleModalOpen()
        }
    }, [virtualAccount])

    const topUpAmounts = [
        { value: 1000000, label: 'Rp1.000.000' },
        { value: 2000000, label: 'Rp2.000.000' },
        { value: 5000000, label: 'Rp5.000.000' },
        { value: 10000000, label: 'Rp10.000.000' },
        { value: 20000000, label: 'Rp20.000.000' },
        { value: 50000000, label: 'Rp50.000.000' },
    ];

    function copyVA(text) {
        navigator.clipboard.writeText(virtualAccount.number).then(() => {
            alert('No Virtual Account berhasil dicopy!');
        }).catch(err => {
            alert('Failed to copy VA');
            // Handle clipboard permission errors or other issues (optional)
        });
    }

    function copyOrderId(text) {
        navigator.clipboard.writeText(text).then(() => {
            alert('order id berhasil dicopy');
        }).catch(err => {
            alert('Failed to copy order id');
            // Handle clipboard permission errors or other issues (optional)
        });
    }

    function handleSnackClose() {
        setOpenSnack(false)
    }

    return (
        <>
            <Snackbar open={openSnack} anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={6000} onClose={handleSnackClose}>
                {/* <Alert
                    onClose={handleSnackClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Pembayaran VA sudah masuk
                </Alert> */}
                <Alert onClose={handleSnackClose} severity="success" variant='filled'>
                    <AlertTitle>Success</AlertTitle>
                    Pembayaran VA sudah masuk
                </Alert>
            </Snackbar>
            <Dialog
                open={open}
                onClose={handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Pembayaran Virtual Account Telah Dibuat"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p>Silakan gunakan kode virtual account berikut untuk menyelesaikan pembayaran Anda</p>
                        <div style={{ padding: "10px" }}>
                            <table>
                                <tr style={{ height: "45px" }}>
                                    <td>Nomor VA</td>
                                    <td>:</td>
                                    <td>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <span id='span' style={{ marginLeft:"20px" }}>{virtualAccount.number}</span>
                                            <button style={{ padding: "6px", marginLeft: "20%" }} onClick={copyVA}>Copy</button>
                                        </div>
                                    </td>
                                </tr>
                                <tr style={{ height: "45px" }}>
                                    <td>Nominal</td>
                                    <td>:</td>
                                    <td><span style={{ marginLeft:"20px" }}>Rp{virtualAccount.nominal}</span></td>
                                </tr>
                                {/* <tr style={{ height: "45px" }}>
                                    <td>No Transaksi</td>
                                    <td>:</td>
                                    <td>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                            <span>{virtualAccount.orderId}</span>
                                            <button style={{ padding: "6px", marginLeft: "20px"}} onClick={{ copyOrderId }}>copy</button>
                                        </div>
                                    </td>
                                </tr> */}
                                <tr style={{ height: "45px" }}>
                                    <td>Status</td>
                                    <td>:</td>
                                    <td><span style={{ backgroundColor: "orange", padding: "7px", borderRadius: "30px", marginLeft: "20px" }}>Menunggu Pembayaran</span></td>
                                </tr>
                            </table>
                        </div>
                        <p><i>Jangan tutup jendela ini sebelum Anda menyelesaikan pembayaran. Menutup jendela ini sebelum pembayaran dikonfirmasi dapat menyebabkan transaksi gagal </i></p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleModalClose}>Close</Button> */}
                    <Button onClick={handleModalClose} autoFocus>
                        Check Status
                    </Button>
                </DialogActions>
            </Dialog>
            <div style={{ padding: "20px", width: "100%", margin: "0 auto" }}>
                <h2 style={{ marginBottom: "20px" }}>Top Up</h2>
                <form onSubmit={handleTopUpSubmit}>
                    <div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                        <h3>Select Top Up Amount</h3>
                        <ul style={{ display: "flex", flexWrap: "wrap", listStyle: "none", padding: "0" }}>
                            {topUpAmounts.map((amount) => (
                                <li key={amount.value} style={{ marginRight: "10px", marginBottom: "10px" }}>
                                    <button
                                        style={{
                                            padding: "10px 20px",
                                            backgroundColor: topUpAmount === amount.value ? "#3f51b5" : "lightgray",
                                            color: topUpAmount === amount.value ? "white" : "black",
                                            border: "none",
                                            cursor: "pointer",
                                            borderRadius: "5px",
                                        }}
                                        onClick={() => setTopUpAmount(amount.value)}
                                        type="button"
                                    >
                                        {amount.label}
                                    </button>
                                </li>
                            ))}
                        </ul>
                        <TextField
                            label="Amount"
                            variant="standard"
                            type="number"
                            InputLabelProps={{ shrink: true }}
                            value={topUpAmount} // Pre-fill if selected from buttons
                            onChange={handleTopUpAmountChange}
                            style={{ width: "200px" }}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <h3>Choose Payment Method</h3>
                        <FormControl>
                            <RadioGroup value={paymentMethod} onChange={handlePaymentMethodChange}>
                                <FormControlLabel value="nobu_va" control={<Radio />} label="Bank NOBU Virtual Account" />
                                <FormControlLabel value="e_wallet" control={<Radio disabled />} label="E-Wallet (Coming Soon)" />
                                {/* Add more payment methods as needed */}
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <Button variant="contained" type="submit" style={{ marginTop: "20px" }}>
                        Top Up Now
                    </Button>
                </form>
                <h2 style={{ marginTop: "20px", marginBottom: "10px"}}>Cara untuk topup account B2B untuk kebutuhan transfer dana real time</h2>
                <ol>
                    <li>Pilih NMID</li>
                    <li>Pilih nominal</li>
                    <li>Lakukan pembayaran VA dengan memilih transfer dengan tujuan bank penerima adalah Bank National Nobu</li>
                    <li>Pembayaran dapat dilakukan melalui metode BI FAST maupun RTOL</li>
                </ol>
            </div>
                
        </>
    )
}
