import React, { useState } from 'react'
import { url } from '../BaseUrl';
import axios from 'axios'
import './login.css';


export const Login = (props) => {
    const [form, setForm] = useState({
        email: '',
        password: ''
    })
    const onSubmit = async (e) => {   
        e.preventDefault()
        try {
            const user = await axios.post(`${url}/login`, form)
            props.setAuth(user.data);
            localStorage.setItem('authuser', JSON.stringify(user.data))   
            localStorage.setItem('grid', false)   
        } catch (error) {
            alert(error.response.data.error)
            console.log(error)
        }
    }

    const onChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className="container mx-auto">
            <div className="card">
                <img src="./logo-piro.png" width={100} alt="" />
                <h2>Piro login</h2>
                <h3>Selamat datang di piro.id</h3>
                <form onSubmit={onSubmit}>
                    <input name='email' value={form.email} onChange={onChange} className='mb-3' type="email" placeholder="Masukan email" />
                    <input name='password' value={form.password} onChange={onChange} className='mb-3' type="password" placeholder="Masukan password" />

                    <div className="btn-email" style={{ display: "flex", justifyContent: "space-between"}}>
                        <a href='/register'>
                            <button type="button">Register</button>
                        </a>
                        <button type="button">Lupa Password??</button>
                    </div>
                    <p>.
                        Syarat dan ketemtuan dan juga kebijakan privasi ada dilink berikut
                        <a href="#/"> Disini</a>
                    </p>

                    <div className="card-bottom">
                        <a href="#/">Hubungi cs.piro.id</a>
                        <button type="submit" onClick={onSubmit}>Login</button>
                    </div>
                </form>
            </div>

            <div className="footer">
                <select name="select">
                    <option value="English (United States)">
                        Indonesia (Bahasa Indonesia)
                    </option>
                </select>

                <div className="footer-span">
                    <span>cs@piro.id</span>
                   
                </div>
            </div>
        </div>
    )
}
