import React, { useState } from 'react'
import axios from 'axios'
import './Register.css';
import { url } from '../../../BaseUrl';
import { useNavigate } from 'react-router-dom';


export const Register = (props) => {
    const [form, setForm] = useState({
        email: '',
        password: ''
    })
    const navigate= useNavigate()
    const onSubmit = async (e) => {   
        e.preventDefault()
        try {
            const user = await axios.post(`${url}/register`, form)
            navigate('/workspaces')
        } catch (error) {
            alert("Email sudah terdaftar")
            console.log(error)
        }
    }

    const onChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className="container mx-auto">
            <div className="card">
                <img src="./logo-piro.png" width={100} alt="" />
                <h2>Piro Register</h2>
                <h3>Selamat datang di piro.id</h3>
                <form onSubmit={onSubmit}>
                    <input name='email' value={form.email} onChange={onChange} className='mb-3' type="email" placeholder="Masukan email" />
                    <input name='password' value={form.password} onChange={onChange} className='mb-3' type="password" placeholder="Masukan password" />

                    <div className="btn-email mb-5">
                        <a href='/'>
                            <button type="button">Sudah punya akun?? login</button>
                        </a>
                    </div>
                    <p>
                        Syarat dan ketemtuan dan juga kebijakan privasi ada dilink berikut
                        <a href="#/"> Disini</a>
                    </p>

                    <div className="card-bottom">
                        <a href="#/">Hubungi cs.piro.id</a>
                        <button type="submit" onClick={onSubmit}>Login</button>
                    </div>
                </form>
            </div>

            <div className="footer">
                <select name="select">
                    <option value="English (United States)">
                        Indonesia (Bahasa Indonesia)
                    </option>
                </select>

                <div className="footer-span">
                    <span>cs@piro.id</span>
                   
                </div>
            </div>
        </div>
    )
}
