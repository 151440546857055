import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AppContext } from '../App'
import axios from 'axios'
import { url } from '../BaseUrl'
import { useParams, useSearchParams } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import Download from '@mui/icons-material/FileDownloadRounded';
import CustomMaterialPagination from '../components/CustomPagination'
import moment from 'moment'

const customStyles = {
  headRow: {
    style: {
      border: 'none',
    },
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      borderRadius: '25px',
      outline: '1px solid #FFFFFF',
    },
  },
  pagination: {
    style: {
      border: 'none',
    },
  },
};


export const Home = () => {
  const context = useContext(AppContext)
  const [files, setFiles] = useState()
  const [loading, setLoading] = useState()
  const params = useParams()
  let [searchParams] = useSearchParams();


  const columns = [
    {
      cell: () => <svg
        fill="#43A047"
        aria-hidden="true"
        className="MuiSvgIcon-root"
        viewBox="0 0 24 24"
      >
        <path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z"></path>
      </svg>,
      width: '56px', // custom width for icon button
      style: {
        borderBottom: '1px solid #FFFFFF',
        marginBottom: '-1px',
      },
    },
    {
      name: 'Transaction Date',
      selector: row => moment(row.dateOfTransaction).format('yyyy-MM-DD 00:00'),
      sortable: true,
      style: {
        color: 'rgba(0,0,0,.54)',
      },
    },
    // {
    //   name: 'File Name',
    //   selector: row => row.filename,
    //   sortable: true,
    //   grow: 2,
    //   style: {
    //     color: '#202124',
    //     fontSize: '14px',
    //     fontWeight: 500,
    //   },
    // },
    {
      name: 'Description',
      selector: row => row.description,
      width: "400px",
      sortable: true,
      style: {
        color: 'rgba(0,0,0,.54)',
      },
    },
    // {
    //   name: 'Size',
    //   selector: row => row.filesize / 1000 + ' KB',
    //   sortable: true,
    //   style: {
    //     color: 'rgba(0,0,0,.54)',
    //   },
    // },
    {
      cell: row => {
        const [nmid, clientId] = context.nmid.split(":")
        const { page } = params
        let path = url
        
        if (page === 'qris') path += `/transaction/${clientId}/${row.dateOfTransaction}`
        else if (page === "bi-fast") path += `/bi-fast/${row.id}/${context.auth.user.id}/${nmid}`
        else if (page === "va") path += `/export-va/${clientId}/${row.dateOfTransaction}`
        else path += `/export-rtol/${clientId}/${row.dateOfTransaction}`
        return (
          <a target="_blank" rel="noreferrer" href={path}><Download /></a>
        )
      },
      allowoverflow: true,
      button: true,
      width: '56px',
    },
  ];

  const callbackFetch = useCallback(
    async () => {
      const { page } = params
      if (page === 'qris') {
        let query = ""
        let uri = `${url}/files`
        if (context.nmid) {
          const [nmid] = context.nmid.split(":")
          query += `nmid=${nmid}&`
        }
        if (context.auth.user.id) query += `uuid=${context.auth.user.id}&`
        if (context.tablePage) query += `page=${context.tablePage}&`
        if (context.tableRowsPerPage) query += `limit=${context.tableRowsPerPage}`
        if (query) uri = uri + "?" + query
        // context.setCurrentFolder(urlquery ? urlquery : context.mainFolder)
        try {
          setLoading(true)
          const response = await axios.get(uri)
          setFiles(response.data.docs)
          context.setTotalDocs(response.data.totalDocs)
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      }
      else if (page === "bi-fast") {
        setFiles([])
        let query = ""
        let uri = `${url}/bi-fast`
        if (context.nmid) {
          const [nmid] = context.nmid.split(":")
          query += `nmid=${nmid}&`
        }
        if (context.auth.user.id) query += `uuid=${context.auth.user.id}&`
        if (context.tablePage) query += `page=${context.tablePage}&`
        if (context.tableRowsPerPage) query += `limit=${context.tableRowsPerPage}`
        if (query) uri = uri + "?" + query
        // context.setCurrentFolder(urlquery ? urlquery : context.mainFolder)
        try {
          setLoading(true)
          const response = await axios.get(uri)
          setFiles(response.data.docs)
          context.setTotalDocs(response.data.totalDocs)
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      } else if (page === "va") {
        setFiles([])
        let query = ""
        let uri = `${url}/va`
        if (context.nmid) {
          const [nmid] = context.nmid.split(":")
          query += `nmid=${nmid}&`
        }
        if (context.auth.user.id) query += `uuid=${context.auth.user.id}&`
        if (context.tablePage) query += `page=${context.tablePage}&`
        if (context.tableRowsPerPage) query += `limit=${context.tableRowsPerPage}`
        if (query) uri = uri + "?" + query
        // context.setCurrentFolder(urlquery ? urlquery : context.mainFolder)
        try {
          setLoading(true)
          const response = await axios.get(uri)
          setFiles(response.data.docs)
          context.setTotalDocs(response.data.totalDocs)
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      } else {
        setFiles([])
        let query = ""
        let uri = `${url}/rtol`
        if (context.nmid) {
          const [nmid] = context.nmid.split(":")
          query += `nmid=${nmid}&`
        }
        if (context.auth.user.id) query += `uuid=${context.auth.user.id}&`
        if (context.tablePage) query += `page=${context.tablePage}&`
        if (context.tableRowsPerPage) query += `limit=${context.tableRowsPerPage}`
        if (query) uri = uri + "?" + query
        // context.setCurrentFolder(urlquery ? urlquery : context.mainFolder)
        try {
          setLoading(true)
          const response = await axios.get(uri)
          setFiles(response.data.docs)
          context.setTotalDocs(response.data.totalDocs)
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      }
    },
    [context, params],
  )

  useEffect(() => {
    callbackFetch()
  }, [context, callbackFetch, searchParams])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const nmid = urlParams.get('nmid');
    const clientId = urlParams.get('clientId');
    const alias = urlParams.get('alias');

    context.setNmid(`${nmid}:${clientId}:${alias}`)
  }, [])

  return (
    <div className="home" style={{ width: '100%', marginLeft: '2vw' }}>
      {loading ? (
        <div className='loading'>Loading</div>
      ) : (
        <>
          <div className='table'>
            <DataTable
              columns={columns}
              data={files}
              customStyles={customStyles}
              highlightOnHover
              pointerOnHover
              pagination
              paginationComponent={() => <CustomMaterialPagination />}
            // theme='solarized'
            />
          </div>
          {files?.length === 0 ?
            (<p style={{ margin: 'auto', width: 'fit-content', marginTop: '5vh', fontSize: '13.75px' }}>Nothing to see here</p>) : (<></>)}
        </>
      )}
    </div>
  )
}
