import { NavLink, useLocation } from 'react-router-dom';
// export const url="http://localhost:4000"
export const url="https://payloadcms.piro.my.id"
export const urlCloudFunction="https://us-central1-doc-basic.cloudfunctions.net/dashuserbalance2gen/v1/users-by-dash "

export const LinkWithQuery = ({ children, to, ...props }) => {
  const { search } = useLocation();

  return (
    <NavLink to={to + search} {...props}>
      {children}
    </NavLink>
  );
};