import { Home } from "./pages/Home";
import { Leftbar } from "./components/Leftbar";
import { Navbar } from "./components/Navbar";
import React, { useEffect, useState } from "react";
import { Login } from "./pages/Login";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import axios from 'axios'
import { Redirect } from "./pages/Redirect";
import CheckStatus from "./pages/CheckStatus";
import CheckStatusSnap from "./pages/CheckStatusSnap";
import Mutation from "./pages/Mutation";
import TopUp from "./pages/TopUp";
import CheckBifastRefId from "./pages/CheckBifastRefId";
import { Register } from "./pages/auth/register/Register";
import { urlCloudFunction } from "./BaseUrl";
import { Workspaces } from "./pages/dashboard/workspaces";
import Saldo from "./pages/Saldo";

export const AppContext = React.createContext()
function App() {
  const [dark, setDark] = useState(false)
  const [tablePage, setTablePage] = useState(0)
  const [tableDocs, setTotalDocs] = useState(0)
  const [balance, setBalance] = useState(0)
  const [tableRowsPerPage, setTableRowPerPage] = useState(10)
  const darkTheme = createTheme({
    palette: {
      mode: dark ? 'dark' : 'light',
    },
  });

  const [auth, setAuth] = useState(null)
  const [nmid, setNmid] = useState()
  const [mainFolder, setMainFolder] = useState()
  const [currentFolder, setCurrentFolder] = useState()
  const [loading, setLoading] = useState(false)


  const getBalance = async (clientId, nmid) => {
    const response = await axios.get(`${urlCloudFunction}`, {
      headers: {
        'client_Id': clientId,
        'nobuNMID': nmid,
        'Authorization': 'Basic ZGFzaDokJSREJEFTSA=='
      }
    })
    setBalance(response.data)
  }

  const ContextValue = {
    auth, mainFolder, setMainFolder, currentFolder, setCurrentFolder, loading, setLoading, dark, tablePage, setTablePage,
    tableRowsPerPage, setTableRowPerPage, tableDocs, setTotalDocs, nmid, setNmid, getBalance, balance
  }

  useEffect(() => {
    if (localStorage.getItem('authuser') !== null || localStorage.getItem('authuser') !== undefined) {
      const user = JSON.parse(localStorage.getItem('authuser'))
      if (user) {
        setAuth(user)
      }
    }
    if (localStorage.getItem('dark')) {
      localStorage.getItem('dark') === 'true' ? setDark(true) : setDark(false)
    }
    if (localStorage.getItem('mainFolder')) {
      setMainFolder(localStorage.getItem('mainFolder'))
    }
  }, [])

  function handleDark() {
    setDark(prevState => {
      return !prevState
    })
    localStorage.setItem('dark', `${!dark}`)
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline>
          <AppContext.Provider value={ContextValue}>
            <Routes>
              <Route path="/" element={
                <div className="App">
                  <Redirect />
                </div>}
              />
              <Route path="/check-status" element={
                <div className="App">
                   {
                    (auth) ?
                      (<><Navbar loading={loading} photo={auth.photoURL} setAuth={setAuth} handleDark={handleDark} />
                        <div className="app" style={{ display: 'flex', flexDirection: 'row' }}>
                          <Leftbar />
                         <CheckStatus>  </CheckStatus>
                        </div></>) : <Login setAuth={setAuth} />
                  }
                </div>}
              />
              <Route path="/check-status-snap" element={
                <div className="App">
                   {
                    (auth) ?
                      (<><Navbar loading={loading} photo={auth.photoURL} setAuth={setAuth} handleDark={handleDark} />
                        <div className="app" style={{ display: 'flex', flexDirection: 'row' }}>
                          <Leftbar />
                         <CheckStatusSnap></CheckStatusSnap>
                        </div></>) : <Login setAuth={setAuth} />
                  }
                </div>}
              />
              <Route path="/saldo" element={
                <div className="App">
                   {
                    (auth) ?
                      (<><Navbar loading={loading} photo={auth.photoURL} setAuth={setAuth} handleDark={handleDark} />
                        <div className="app" style={{ display: 'flex', flexDirection: 'row' }}>
                          <Leftbar />
                         <Saldo></Saldo>
                        </div></>) : <Login setAuth={setAuth} />
                  }
                </div>}
              />
               <Route path="/workspaces" element={
                <div className="App">
                   {
                    (auth) ?
                      (<><Navbar loading={loading} photo={auth.photoURL} setAuth={setAuth} handleDark={handleDark} />
                        <div className="app" style={{ display: 'flex', flexDirection: 'row' }}>
                         <Workspaces></Workspaces>
                        </div></>) : <Login setAuth={setAuth} />
                  }
                </div>}
              />
              <Route path="/register" element={
                <Register></Register>
              }>
              </Route>
              <Route path="/check-status-rrn" element={
                <div className="App">
                   {
                    (auth) ?
                      (<><Navbar loading={loading} photo={auth.photoURL} setAuth={setAuth} handleDark={handleDark} />
                        <div className="app" style={{ display: 'flex', flexDirection: 'row' }}>
                          <Leftbar />
                         <CheckStatus>  </CheckStatus>
                         </div></>) : <Login setAuth={setAuth} />
                  }
                </div>}
              />
              <Route path="/check-bifast-ref-id" element={
                <div className="App">
                   {
                    (auth) ?
                      (<><Navbar loading={loading} photo={auth.photoURL} setAuth={setAuth} handleDark={handleDark} />
                        <div className="app" style={{ display: 'flex', flexDirection: 'row' }}>
                          <Leftbar />
                         <CheckBifastRefId>  </CheckBifastRefId>
                        </div></>) : <Login setAuth={setAuth} />
                  }
                </div>}
              />
              <Route path="/topup" element={
                <div className="App">
                   {
                    (auth) ?
                      (<><Navbar loading={loading} photo={auth.photoURL} setAuth={setAuth} handleDark={handleDark} />
                        <div className="app" style={{ display: 'flex', flexDirection: 'row' }}>
                          <Leftbar />
                         <TopUp></TopUp>
                        </div></>) : <Login setAuth={setAuth} />
                  }
                </div>}
              />
               <Route path="/mutation" element={
                <div className="App">
                   {
                    (auth) ?
                      (<><Navbar loading={loading} photo={auth.photoURL} setAuth={setAuth} handleDark={handleDark} />
                        <div className="app" style={{ display: 'flex', flexDirection: 'row' }}>
                          <Leftbar />
                         <Mutation></Mutation>
                        </div></>) : <Login setAuth={setAuth} />
                  }
                </div>}
              />
              <Route path="/:page" element={
                <div className="App">
                  {
                    (auth) ?
                      (<><Navbar loading={loading} photo={auth.photoURL} setAuth={setAuth} handleDark={handleDark} />
                        <div className="app" style={{ display: 'flex', flexDirection: 'row' }}>
                          <Leftbar />
                          <Home auth={auth} />
                        </div></>) : <Login setAuth={setAuth} />
                  }
                </div>}
              />
            </Routes>
          </AppContext.Provider>
        </CssBaseline>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
